
export const ratingFormatter = (rating) => {
  // test coverage
  if (typeof rating === "string") {
    rating = parseFloat(rating);
  }
  if (isNaN(rating) || rating === undefined || rating === null) return 0;
  if (rating <= 1) {
    rating = Math.round(rating * 100);
  }
  return rating;
};
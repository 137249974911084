import { useCallback, useEffect, useState } from "react";
import { debounce } from 'debounce';

const registerListener = (event, fn) => {
  if (window.addEventListener) {
    window.addEventListener(event, fn);
  } else {
    window.attachEvent('on' + event, fn);
  }
};

const getPosition = (rect, el, margin) => {
  if (!rect) {
    rect = el && typeof el.getBoundingClientRect == "function" && el.getBoundingClientRect();
  }
  if (!rect) return false;

  let targetRect = { top: Math.ceil(rect.top), left: Math.ceil(rect.left), bottom: Math.floor(rect.bottom), right: Math.floor(rect.right) };

  if (margin) {
    targetRect = Object.entries(targetRect)
      .reduce((rect, [key, value]) => Object.assign(rect, { [key]: value + (key in margin ? margin[key] : 0) }), {});
  }

  return targetRect;
};

const isIntersecting = (rect, el, partial, margin) => {

  if (!rect) {
    rect = el && typeof el.getBoundingClientRect == "function" && el.getBoundingClientRect();
  }
  if (!rect) return false;

  let targetRect = { top: Math.ceil(rect.top), left: Math.ceil(rect.left), bottom: Math.floor(rect.bottom), right: Math.floor(rect.right) };
  const viewPort = {
    top: 0,
    left: 0,
    bottom: (window && window.innerHeight || document.documentElement.clientHeight),
    right: (window && window.innerWidth || document.documentElement.clientWidth),
  };

  if (margin) {
    targetRect = Object.entries(targetRect)
      .reduce((rect, [key, value]) => Object.assign(rect, { [key]: value + (key in margin ? margin[key] : 0) }), {});
  }

  return partial ?
    (
      (targetRect.left >= viewPort.left && targetRect.left <= viewPort.right || targetRect.right >= viewPort.left && targetRect.right <= viewPort.right) &&
      (targetRect.top >= viewPort.top && targetRect.top <= viewPort.bottom || targetRect.bottom >= viewPort.top && targetRect.bottom <= viewPort.bottom)
    ) : (
      targetRect.top >= viewPort.top &&
      targetRect.left >= viewPort.left &&
      targetRect.bottom <= viewPort.bottom &&
      targetRect.right <= viewPort.right
    );
};

export const useIntersection = ({ partial = true, margin = undefined } = {}) => {

  const [isVisible, setVisible] = useState(undefined);
  // const [rects, setRects] = useState(undefined);
  const [node, setNode] = useState(null);

  const innerRef = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (!node) return;
    let observer;
    let throttledFunction;
    if (('IntersectionObserver' in window) && typeof margin !== "object") {
      observer = new IntersectionObserver(
        ([entry]) => {
          setVisible(isIntersecting(entry.boundingClientRect, undefined, partial, margin));
        }, { root: null, rootMargin: "0px", threshold: partial ? 0 : 1.0 }
      );
      observer.observe(node);
    } else {
      throttledFunction = debounce((event) => {
        setVisible(isIntersecting(undefined, node, partial, margin));
      }, 100);

      registerListener('load', throttledFunction);
      registerListener('scroll', throttledFunction);
      registerListener('wheel', throttledFunction);
      registerListener('resize', throttledFunction);
      registerListener('gestureend', throttledFunction);
      throttledFunction("FIRST");
    }

    return () => {
      if (observer) {
        observer.unobserve(node);
      }
      if (throttledFunction) {
        throttledFunction.clear();
        window.removeEventListener('load', throttledFunction);
        window.removeEventListener('scroll', throttledFunction);
        window.removeEventListener('wheel', throttledFunction);
        window.removeEventListener('resize', throttledFunction);
        window.removeEventListener('gestureend', throttledFunction);
      }
    };
  }, [node]);

  return { isVisible, innerRef };
};


export const usePosition = ({ margin = undefined } = {}) => {

  const [position, setPosition] = useState(undefined);
  // const [rects, setRects] = useState(undefined);
  const [node, setNode] = useState(null);

  const innerRef = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (!node) return;
    let throttledFunction = debounce(() => {
      setPosition(getPosition(undefined, node, margin));
    }, 100);

    registerListener('load', throttledFunction);
    registerListener('scroll', throttledFunction);
    registerListener('wheel', throttledFunction);
    registerListener('resize', throttledFunction);
    registerListener('gestureend', throttledFunction);
    throttledFunction();

    return () => {
      if (throttledFunction) {
        throttledFunction.clear();
        window.removeEventListener('load', throttledFunction);
        window.removeEventListener('scroll', throttledFunction);
        window.removeEventListener('wheel', throttledFunction);
        window.removeEventListener('resize', throttledFunction);
        window.removeEventListener('gestureend', throttledFunction);
      }
    };
  }, [node]);

  return { position, innerRef };
};
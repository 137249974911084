let isOn = false;
let scrollbarSize;
let scrollTop;

const getScrollbarSize = () => {
  if (typeof scrollbarSize !== 'undefined') return scrollbarSize;

  let doc = document.documentElement;
  let dummyScroller = document.createElement('div');
  dummyScroller.setAttribute('style', 'width:99px;height:99px;' + 'position:absolute;top:-9999px;overflow:scroll;');
  doc.appendChild(dummyScroller);
  scrollbarSize = dummyScroller.offsetWidth - dummyScroller.clientWidth;
  doc.removeChild(dummyScroller);
  return scrollbarSize;
};

const hasScrollbar = () => {
  return document.documentElement.scrollHeight > window.innerHeight;
};

const on = (doc) =>  {
  if (typeof document === 'undefined' || isOn) return;
  doc = doc || document.documentElement;
  scrollTop = window.pageYOffset;
  if (hasScrollbar()) {
    doc.style.width = 'calc(100% - '+ getScrollbarSize() +'px)';
  } else {
    doc.style.width = '100%';
  }
  doc.style.position = 'fixed';
  doc.style.top = -scrollTop + 'px';
  doc.style.overflow = 'hidden';
  isOn = true;
};

const off = (doc) => {
  if (typeof document === 'undefined' || !isOn) return;
  doc = doc || document.documentElement;
  doc.style.width = '';
  doc.style.position = '';
  doc.style.top = '';
  doc.style.overflow = '';
  window.scroll(0, scrollTop);
  isOn = false;
};

const noScroll = { on, off };

getScrollbarSize();
export {
  noScroll
};
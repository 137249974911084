import './style/bootstrap.pure.scss';

import "swiper/css/bundle";
import './style/taketravel-carousel.pure.scss';

import 'react-date-range/dist/styles.css'; // main style file
import './style/datepicker-style.pure.scss';

import './style/success-icon.pure.scss';

import './style/youtube-rewrite.pure.scss';

import 'react-loading-skeleton/dist/skeleton.css';

import 'react-lazy-load-image-component/src/effects/blur.css';
import './components/mobile/blocks/animation-mobile-block.pure.scss';
import './screens/animation-screen.pure.scss';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './app';
import { appRoot } from './utils';

console.log(`v${process.env.VERSION}`);
ReactDOM.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>,
  appRoot());

import { useCallback, useEffect, useState } from "react";

const registerListener = (event, fn) => {
  if (window.addEventListener) {
    window.addEventListener(event, fn);
  } else {
    window.attachEvent('on' + event, fn);
  }
};

export const useSize = ({ initialWidth = 0, initialHeight = 0 } = {}) => {

  const [width, setWidth] = useState(initialWidth);
  const [height, setHeight] = useState(initialHeight);
  const [node, setNode] = useState(null);

  const innerRef = useCallback((node) => {
    setNode(node);
  }, []);

  useEffect(() => {
    if (!node) return;
    setWidth(node.offsetWidth);
    setHeight(node.offsetHeight);
  });

  return { width, height, innerRef };
};

export const compareTypeables = (a, b) => {
  if (a.order != b.order) return a.order - b.order;
  if (a.type > b.type) return 1;
  if (a.type < b.type) return -1;
  return 0;
};

export const compareDivisions = (a, b) => {
  if (a.type.order != b.type.order) return a.type.order - b.type.order;
  if (a.type.type > b.type.type) return 1;
  if (a.type.type < b.type.type) return -1;
  return 0;
};

export const compareGeopos = (a, b) => {
  if (a.order != b.order) return a.order - b.order;
  if (a.rating != b.rating) return a.rating - b.rating;
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
};

export const comparePlaces = (a, b) => {
  if (a.rating != b.rating) return a.rating - b.rating;
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
};

export const comparePrograms = (a, b) => {
  if (a.order != b.order) return a.order - b.order;
  if (a.name > b.name) return 1;
  if (a.name < b.name) return -1;
  return 0;
};
import { useCallback, useEffect, useMemo, useState } from "react";
import { useStoreContext } from "../model/store";
import { usePlace } from "./hook-where-are";
import { useWindowSize } from "./hook-window-size";
import { useCancellable } from "./hook-cancelable";
import { useWhereAre } from ".";
import { debounce } from "debounce";
import { useLocation } from "react-router";
import QueryParams from "../utils/query-params";

const equalsArray = (a, b) => {
  if (a.length != b.length) return false;
  return a.slice().sort().join("") === b.slice().sort().join("");
};

const COUNT = {
  xl: 4,
  lg: 3,
  md: 2
};

export function useFilteredServices({ everyRandom }) {
  const { serviceStore, countryStore, cityStore, divisionStore, categoryStore } = useStoreContext();

  const location = useLocation();
  const { size } = useWindowSize();
  const place = usePlace();
  const { where } = useWhereAre();

  const [queryParams, setQueryParams] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const [filtered, setFiltered] = useState({ total: -1, list: [] });

  const { cancellablePromise } = useCancellable();

  const prepareFilter = (place, queryParams, { total = -1, amount = 0 } = {}) => {

    let category_types = queryParams._category_types;
    if (where.category) {
      let category = categoryStore.getCategory(where.category);
      if (category) {
        category_types = [category.type.type];
      }
    } else if (where.division && !category_types.length) {
      let categories = categoryStore.getCategories({ division: where.division });
      category_types = categories.map(categ => categ.type.type);
    }

    const totalLoaded = total < 0 ? Number.MAX_SAFE_INTEGER : total;
    const limitPerPage = +process.env.LOAD_PER_PAGE;
    const amountRandom = everyRandom > 0 ? parseInt(Math.min(amount + limitPerPage, totalLoaded) / everyRandom) : 0;

    const [from, to] = queryParams._date;
    const [min, max] = queryParams._price;

    const filter = {
      category_types: Array.isArray(category_types) ? category_types.join(",") : category_types,
      country: place.country,
      city: place.city,
      datefrom: from || "",
      dateto: to || "",
      minprice: min || 0,
      maxprice: max || 0,
      sorton: queryParams.sorton,

      limit: limitPerPage - (amount + limitPerPage + amountRandom) % (COUNT[size] || 1),
      offset: amount
    };

    return filter;
  };

  const onLoad = useMemo(() => {
    return debounce((filter) => {
      setLoading(true);
      cancellablePromise(serviceStore.filterServices(filter), "filter")
        .then(({ total, list }) => {
          setLoading(false);
          setFiltered(a => ({ total, list: a.list.concat(list) }));
        })
        .catch(() => { });
    }, 100);
  }, []);

  const onLoadMore = useCallback(() => {
    const filter = prepareFilter(place, queryParams, { total: filtered.total, amount: filtered.list.length });
    onLoad(filter);
  }, [filtered, queryParams, place.country, place.city]);

  useEffect(() => {
    if (!place.city && !place.country && !where.main) return;
    if (where.category && !categoryStore.hasCategory(where.category)) return;
    if (where.division && !categoryStore.hasCategories({ division: where.division })) return;

    let newQP = new QueryParams(location);
    if (queryParams &&
      equalsArray(newQP._category_types, queryParams._category_types) &&
      equalsArray(newQP._date, queryParams._date) &&
      equalsArray(newQP._price, queryParams._price) &&
      equalsArray(newQP._sorton, queryParams._sorton)) return;

    setQueryParams(newQP);
    setFiltered({ total: -1, list: [] });

    const filter = prepareFilter(place, newQP);
    onLoad(filter);
  }, [location, queryParams, categoryStore.depSize, place.country, place.city]);

  /**
   * Add extra data to services
   */
  const services = useMemo(() => {
    let list = filtered.list.map(id => {
      let service = serviceStore.getService(id);
      const extra = {};
      if (where.main) {
        /* Division & city on main screen */
        if (service.city_id) {
          extra.__country_id = service.country_id[0];
          extra.country = countryStore.getCountry(extra.__country_id);
          extra.__cities_id = service.city_id;
          extra.cities = cityStore.getCitiesList(extra.__cities_id);
        }
        if (service.division_id && service.division_id.length == 1) {
          extra.__division_id = service.division_id[0];
          extra.division = divisionStore.getDivision(extra.__division_id);
        }
      } else if (where.country) {
        /* City on country screen */
        extra.__cities_id = (service.city_id || []).slice().sort().filter(cityId => {
          const city = cityStore.getCity(cityId);
          return city && city.country_id === where.country;
        });
        if (extra.__cities_id.length) {
          extra.cities = cityStore.getCitiesList(extra.__cities_id);
        }
      } else if (where.city) {
        /* Division on city screen */
        extra.__division_id = (service.division_id || []).slice().sort().find(divisionId => {
          const division = divisionStore.getDivision(divisionId);
          return division && division.city_id === where.city;
        });
        if (extra.__division_id) {
          extra.division = divisionStore.getDivision(extra.__division_id);
        }
      } else if (where.division || where.category) {
        /* Category on division screen */
        if (service.category_id && service.category_id.length > 1) {
          extra.__category_id = service.category_id.find((id) => categoryStore.hasCategory(id));
          extra.category = categoryStore.getCategory(extra.__category_id);
        }
      }
      return {
        key: service.id,
        data: { ...service, ...extra }
      };
    });

    if (everyRandom > 0 && list.length) {
      const amount = list.length;
      const total = filtered.total;

      const amountRandom = parseInt(amount / everyRandom);
      const restRound = amount % everyRandom;
      let withRandom = [];

      for (let i = 0; i < amountRandom; i++) {
        let part = list.slice(i * everyRandom, (i + 1) * everyRandom);
        part.push({ random: true, key: `rnd${i + 1}`, data: {} });
        withRandom = withRandom.concat(part);
      }
      list = withRandom.concat(list.slice(amountRandom * everyRandom));
      if (amount === total && restRound / everyRandom > 0.4) {
        list.push({ random: true, key: `rnd${amountRandom + 1}`, data: {} });
      }
    }
    return list;
  }, [
    filtered.list,
    cityStore.cities,
    divisionStore.divisions,
  ]);

  return { services, amount: filtered.list.length, isLoading, total: filtered.total, onLoadMore };
}
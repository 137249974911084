// @ts-nocheck
import axios from 'axios';
import qs from 'query-string';
import { LOGIN, LOGOUT, API_OBJECT } from './objects';
import { normalizeRequestData } from '../helpers';

class TakeTravelAPI {

  constructor() {
    this.clientParams = {};
    this.axiosInstance = {};
    this.lang = "";
    this.currency = "";
    this.guestToken = "";
    this.waiting = new Map();
  }

  create(baseURL, { client_id, client_secret }, { type, token }) {
    this.clientParams = { client_id, client_secret };

    this.guestToken = `${type} ${token}`;
    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        Authorization: this.guestToken
      }
    });
  }

  /**
   * @param {lang, currency, token: {type, token}} data 
   */
  change({ lang, currency, token }) {
    if (lang) {
      this.lang = lang;
    }
    if (currency) {
      this.currency = currency;
    }
    if (token) {
      Object.assign(this.axiosInstance.defaults, { headers: { Authorization: `${token.type} ${token.token}` } });
    }
  }

  /* AUTH LOGIC */
  getAuthCode(loginCredentials) {
    return this.post(API_OBJECT.AUTH_CODE, loginCredentials);
  }

  login(loginCredentials, auth_code) {
    return this.post(LOGIN, {
      ...this.clientParams,
      ...loginCredentials,
      auth_code,
    });
  }

  logout() {
    return this.post(LOGOUT, {
      ...this.clientParams,
    });
  }

  /* BASE METHODS */

  post(apiPath, data = {}, cancelToken) {
    return this._call('POST', apiPath, data, cancelToken);
  }

  get(apiPath, data = {}, cancelToken = null) {
    const body = {
      ...data,
      lang: (data && data.lang) || this.lang,
      currency: (data && data.currency) || this.currency,
    };
    return this._call('GET', apiPath, body, cancelToken);
  }

  request(method, apiPath, body, cancelToken) {
    return this._call(method, apiPath, body, cancelToken);
  }

  addRequestInterceptor(onFulfilled, onRejected) {
    return this.axiosInstance.interceptors.request.use(
      onFulfilled ||
      function (config) {
        return config;
      },
      onRejected ||
      function (error) {
        return Promise.reject(error);
      },
    );
  }
  addResponseInterceptor(onFulfilled, onRejected) {
    return this.axiosInstance.interceptors.response.use(
      onFulfilled ||
      function (response) {
        return response;
      },
      onRejected ||
      function (error) {
        return Promise.reject(error);
      },
    );
  }
  removeInterceptor(interceptor) {
    this.axiosInstance.interceptors.request.eject(interceptor);
  }

  /*  CALL API */
  _call(method, apiPath, body, cancelToken) {
    body = normalizeRequestData(body, {
      skipNull: true,
      skipEmptyString: true,
      skipZeroString: true,
    });
    /* build config */
    const config = this._buildConfig(method, apiPath, body);
    if (config.method === "GET" && this.waiting.has(config.url)) {
      return this.waiting.get(config.url);
    }
    /* creating request */
    const request = this.axiosInstance.request({ ...config, cancelToken });
    if (config.method === "GET") {
      this.waiting.set(config.url, request);
      request.finally(() => {
        this.waiting.delete(config.url);
      });
    }
    /* return request instance */
    return request;
  }

  _buildConfig(method, apiPath, body) {
    let path = Array.isArray(apiPath) ? apiPath.map((value) => String(value).trim()).join('/') : String(apiPath).trim();
    const url = String(path).trim();
    const config = { method, url };

    if (method === "POST") {
      let files = [];
      for (let n in body) {
        let v = body[n];
        if (v instanceof File) {
          files.push({ name: n, file: v });
          body[n] = null;
        } else if (Array.isArray(v)) {
          for (let i = 0; i < v.length; i++) {
            let f = v[i];
            if (f instanceof File) {
              files.push({ name: n, file: f });
              v[i] = null;
            }
          }
        }
      }

      if (files.length) {
        let formData = new FormData();
        for (let name in body) {
          if (body[name] === null || (Array.isArray(body[name]) && body[name].every(v => v === null))) continue;
          formData.append(name, body[name]);
        }
        if (files.length === 1) {
          formData.append(`${files[0].name}[]`, files[0].file);
        } else {
          files.forEach(({ file, name }, index) => {
            formData.append(`${name}[${index}]`, file);
          });
        }

        Object.assign(config, { headers: { 'Content-Type': "multipart/form-data" }, data: formData });
      } else {
        Object.assign(config, { data: { ...body } });
      }
    } else {
      Object.assign(config, { url: `${url}?${qs.stringify(body)}` });
    }

    return config;
  }
}

const instance = new TakeTravelAPI();
export default instance;
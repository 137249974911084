export const arrayToObj = (list) => (
  list.reduce((ac, i) => { ac[i.id] = i; return ac; }, {})
);

export const intersection = (a, b) => {
  if (a.length > b.length) {
    return a.filter(x => b.includes(x)) || [];
  } else {
    return b.filter(x => a.includes(x)) || [];
  }
};

export const difference = (a, b) => {
  if (a.length > b.length) {
    return a.filter(x => !b.includes(x)) || [];
  } else {
    return b.filter(x => !a.includes(x)) || [];
  }
};

export const toArray = (list, separator) => {
  if (Array.isArray(list)) return list;
  if (typeof list === "string") return list.split(separator || ",");
  if (typeof list === "number") return [list];
  return [];
};

export const cutString = (str, MAX) => str.substr(0, MAX) + (str.length > MAX ? "..." : "");
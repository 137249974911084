const RTLS = ["ar", "arc", "dv", "fa", "ha", "he", "khw", "ks", "ku", "ps", "ur", "yi"];

export const resources = {
  en: {
    url: process.env.NODE_ENV === 'production' ? `/i18n/en.web.v${process.env.VERSION.split(".").join("_")}.json` : `/i18n/en.web.json`,
    translation: {}
  },
  ru: {
    url: process.env.NODE_ENV === 'production' ? `/i18n/ru.web.v${process.env.VERSION.split(".").join("_")}.json` : `/i18n/ru.web.json`,
    translation: {}
  },
};

export const isRTL = (lng) => RTLS.includes(lng);

export const langs = Object.keys(resources);

import WindowProvider from './window-provider';
import * as WN from './window-name';
import WindowEmitter, { WND_OPENED, WND_CLOSED, WND_CLOSE, WND_OPEN, WND_REMOVED } from './window-emitter';

const WE = {
  WND_OPEN,
  WND_CLOSE,
  WND_CLOSED,
  WND_OPENED,
  WND_REMOVED,
};

export {
  WN,
  WindowEmitter,
  WE
};

export default WindowProvider;

import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export function useTimeFormater(minutes, form = '') {
  const { t } = useTranslation();

  const time = useMemo(() => {
    if (!minutes || minutes <= 0) return "";

    const dd = Math.ceil(minutes / (24 * 60));
    const hh = Math.ceil(minutes / (60));
    const exact_hh = minutes / 60;
    const rest_mm = minutes % 60;

    if (dd > 1) {
      return t(`time.label.${form}_day`, { count: dd });
    }
    if (exact_hh == 2 || hh >= 3) {
      return t(`time.label.${form}_hour`, { count: hh });
    }
    if (exact_hh < 3 && exact_hh > 2) {
      return t(`time.label.${form}_2hour_min`, { mm: rest_mm });
    }
    if (exact_hh < 2 && exact_hh > 1) {
      return t(`time.label.${form}_1hour_min`, { mm: rest_mm });
    }
    if (exact_hh == 1) {
      return t(`time.label.${form}_hour`, { count: 1 });
    }

    return t(`time.label.${form}_min`, { count: minutes });
  }, []);

  return time;
}


export const useTimeFormaterCancel = (minutes) => {
  const { t } = useTranslation();

  const time = useMemo(() => {
    if (!minutes || minutes <= 1) return t("text.cancelNotAvailable");

    const dd = Math.ceil(minutes / (24 * 60));
    const hh = Math.ceil(minutes / (60));

    if (dd > 4) {
      return t(`time.label.notice_day`, { count: dd });
    }

    return t(`time.label.notice_hour`, { count: hh });
  }, []);

  return time;
};
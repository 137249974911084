export const roundPriceCent = (value) => {
  return parseInt(value * 100) / 100;
};

export const cyFormater = (value, currency) => {
  let val = roundPriceCent(value);
  let cur = currency || "";
  switch (cur.toLowerCase()) {
    case "usd":
      return `$${val}`;
    case "eur":
      return `${val}\u00A0€`;
    case "rub":
      return `${val}\u00A0₽`;
    case "aed":
      return `${val}\u00A0${cur.toUpperCase()}`;
    default:
      return [`${val}`, cur.toUpperCase()].filter(a => !!a).join(`\u00A0`);
  }
};

export const cyFormaterRange = (min, max, cur) => {
  cur = cur || "";
  switch (cur.toLowerCase()) {
    case "usd":
      return `$${min}\u00A0–\u00A0${max}`;
    case "rub":
      return `${min}\u00A0–\u00A0${max}\u00A0₽`;
    case "eur":
      return `${min}\u00A0–\u00A0${max}\u00A0€`;
    case "aed":
      return `${min}\u00A0–\u00A0${max}\u00A0${cur.toUpperCase()}`;
    default:
      return [`${min}\u00A0–\u00A0${max}`, cur.toUpperCase()].filter(a => !!a).join(`\u00A0`);
  }
};
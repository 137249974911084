
const NONE = "none";
export const SMALL = "small";       //175x100
export const NORMAL = 'normal';     //278x300
export const GEO = 'geo';           //175x210
export const LARGE = 'large';       //555x600
export const WIDE = 'wide';         //1260x700
export const REVIEW = 'review';     //any size

export const MEDIA_SIZES = { SMALL, NORMAL, GEO, LARGE, WIDE, NONE, REVIEW };

export const IMAGE = 'image';
export const VIDEO = 'video';
export const MEDIA_TYPES = { IMAGE, VIDEO };
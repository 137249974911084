/**
 * Normalize request body
 * @param body - request body 
 * @param {skipNull, skipEmptyString, skipZeroString, skipNames} skipOptions 
 */
export const normalizeRequestData = (body, skipOptions) => {
  const result = {};
  const {skipNull, skipEmptyString, skipZeroString, skipNames} = skipOptions;
  Object.keys(body).forEach((n) => {
    let value = body[n];
    if (!(skipZeroString && value === 0 || 
          skipEmptyString && value === "" || 
          skipNull && (value === undefined || value === null) || 
          skipNames && (skipNames == n || (Array.isArray(skipNames) && skipNames.indexOf(n) != -1) || skipNames[n]))) {
      result[n] = value;
    }
  });
  return result;
};
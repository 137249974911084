// import { onSnapshot } from "mobx-state-tree";
import { taketravelApi } from "../../core/api";
import LocalStorageService from "./localstorage-service";
import Service from "./service";
import { replaceLocation } from "../utils";

class SaveStoreService extends Service {

  constructor(name, store) {
    super(name);
    this.store = store;
  }

  run() {
    import(/* webpackChunkName: "mobx-state-tree" */'mobx-state-tree')
      .then(({ onSnapshot }) => {
        onSnapshot(this.store.userStore, (snapshot) => {
          const { cy, lang, token } = snapshot;
          const dertyUserState = LocalStorageService.getUserState();
          LocalStorageService.setUserState(snapshot);
          taketravelApi.change({ lang, currency: cy, token: { type: token.token_type, token: token.access_token } });
          const window_location = window && window.location;

          if (dertyUserState.lang != lang) {
            let cantChange = replaceLocation({ lang }, true);
            if (cantChange && window_location) {
              setTimeout(() => window_location.reload(), 200);
            }
          } else if (dertyUserState.cy != cy && window_location) {
            setTimeout(() => window_location.reload(), 200);
          }
        });

        onSnapshot(this.store.basketStore, (snapshot) => {
          LocalStorageService.setBasketState(snapshot);
        });
      });
    return Promise.resolve();
  }
}

export default SaveStoreService;
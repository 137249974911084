import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { cyFormater } from "../../core/helpers";


export function usePriceFormater({ price, discount_price = 0, currency, programList }) {
  const { t } = useTranslation();
  const regExp = /\B(?=(\d{3})+(?!\d))/g;

  const memoPrice = useMemo(() => {
    return {
      isFree: !((discount_price || price) > 0),
      regPrice: (discount_price || price) > 0 ? cyFormater(discount_price || price, currency).toString().replace(regExp, `\u00A0`) : t('label.price_free'),
      oldPrice: discount_price > 0 && price > discount_price ? cyFormater(price, currency).toString().replace(regExp, ' ') : "",
      percentDiscount: discount_price > 0 && price > discount_price ? Math.ceil((+((discount_price / price) - 1).toFixed(3)) * -100) : "",
    };
  }, [price, discount_price, currency, programList]);

  return memoPrice;
}
import { addDays, set, compareAsc } from "date-fns";

const timeRange = (from, to, step = 10) => {
  let list = [];
  let fromParse = parseTime(from);
  let toParse = parseTime(to);
  let next = fromParse.minutes;
  do {
    next += step;
    if (next < toParse.minutes && next != toParse.minutes) {
      list.push(minutesToTime(next));
    }
  } while (next < toParse.minutes && next != toParse.minutes);

  return [...new Set([from, ...list, to])];
};

export const availableDates = ({ available_dates = "", unavailable_dates = '', days_of_week = [], count = 366 }) => {
  const result = [];
  const now = set(new Date(), { hours: 0, minutes: 0, seconds: 0 });
  const dates = available_dates.split(",").map(d => parseDate(d)).filter(d => !!d).map(d => {
    let year = now.getFullYear();
    if (compareAsc(d, now) < 0) {
      year += 1;
    }
    d.setYear(year);
    return dateToStr(d);
  });
  const weekDays = days_of_week.map(d => parseInt(d)).filter(d => !isNaN(d));

  for (let i = 0; i < count; i++) {
    let date = addDays(now, i);
    let day = date.getDay() || 7;
    if (!unavailable_dates.includes(dateToStr(date)) && (weekDays.includes(day) || dates.includes(dateToStr(date)))) {
      result.push(date);
    }
  }
  return result;
};

export const availableTimes = ({ available_times = [], withTimeRange = true }) => {
  const TIME_RANGE = /(\d{1,2}:\d{2})\s*-\s*(\d{1,2}:\d{2})/;
  const TIME = /\d{1,2}:\d{2}/;

  let availableTimes = [...available_times];
  if (availableTimes.some(time => {
    let m = time.match(TIME_RANGE);
    return m && m[1] === "00:00" && m[2] === "23:59";
  })) {
    availableTimes = ["00:00-23:59"];
  }

  if (availableTimes.length === 1) {
    let time = availableTimes[0];
    let m = time.match(TIME_RANGE);
    if (m && m[1] != m[2]) {
      return { from: m[1], to: m[2] };
    } else if (TIME.test(time)) {
      return [time];
    } else {
      return [];
    }
  }
  let list = [];
  for (let i = 0; i < availableTimes.length; i++) {
    let time = availableTimes[i];
    let m = time.match(TIME_RANGE);
    if (m && m[1] != m[2] && withTimeRange) {
      list = list.concat(timeRange(m[1], m[2]));
    } else {
      if (TIME.test(time)) {
        list.push(time);
      }
    }
  }
  return [...new Set(list.sort())];
};

// @ts-nocheck
export const parseDate = (a) => {
  if (a instanceof Date) return new Date(a.getTime());
  if (typeof a === "number") return new Date(a);
  if (typeof a === "string") {
    // eslint-disable-next-line no-useless-escape
    const match2 = a.match(/^\s*(\d{1,2})[\.,\\\/\-](\d{1,2})[\.,\\\/\-](\d{2,4})\s*\s?(?:(\d{1,2}):(\d{1,2}))?/);
    if (match2 && match2.length >= 6) {
      let year = parseInt(match2[3]);
      if (year < 2000) {
        year += 2000;
      }
      let month = ("0" + match2[2]).slice(-2);
      let day = ("0" + match2[1]).slice(-2);

      let HH = match2[4] ? ("0" + match2[4]).slice(-2) : undefined;
      let MM = match2[5] ? ("0" + match2[5]).slice(-2) : undefined;
      return new Date(year + "-" + month + "-" + day + (HH && MM ? "T" + HH + ":" + MM : ""));
    }
    // eslint-disable-next-line no-useless-escape
    const match = a.match(/\s*(?:(\d{1,2}):(\d{1,2}))?\s?(\d{1,2})[\.,\\\/\-](\d{1,2})[\.,\\\/\-](\d{2,4})\s*/);
    if (match && match.length >= 6) {
      let year = parseInt(match[5]);
      if (year < 2000) {
        year += 2000;
      }
      let month = ("0" + match[4]).slice(-2);
      let day = ("0" + match[3]).slice(-2);
      let HH = match[1] ? ("0" + match[1]).slice(-2) : undefined;
      let MM = match[2] ? ("0" + match[2]).slice(-2) : undefined;
      return new Date(year + "-" + month + "-" + day + (HH && MM ? "T" + HH + ":" + MM : ""));
    }
  }
  return null;
};

export const dateToStr = (d, short = false) => {
  d = parseDate(d);
  if (!d) return "";
  let day = ('0' + d.getDate()).slice(-2);
  let month = ('0' + (d.getMonth() + 1)).slice(-2);
  let year = d.getFullYear() - (short ? 2000 : 0);
  return day + '.' + month + '.' + year;
};

export const parseTime = (time) => {
  const part = time.split(":").map(a => a.trim());
  const parsed = {
    hh: parseInt(part[0] || 0),
    mm: parseInt(part[1] || 0),
    ss: parseInt(part[2] || 0)
  };
  for (const n in parsed) {
    if (isNaN(parsed[n])) {
      parsed[n] = 0;
    }
  }
  return {
    ...parsed,
    minutes: 60 * parsed.hh + parsed.mm,
    seconds: 60 * 60 * parsed.hh + 60 * parsed.mm + parsed.ss
  };
};

export const dateToMinutes = (date) => {
  date = parseDate(date);
  return Math.floor(date.getTime() / (1000 * 60));
};

export const minutesToTime = (minutes) => {
  return ('0' + parseInt(minutes / 60)).slice(-2) + ":" + ('0' + minutes % 60).slice(-2);
};

export const is24Hours = (times) => {
  return typeof times === "object" && typeof times.from === "string" && typeof times.to === "string" && /0{1,2}:0{2}/.test(times.from) && /23:59/.test(times.to);
};
// @ts-nocheck
import {
  SMALL,
  NORMAL,
  LARGE,
  WIDE,
  GEO,
} from '../model/enum/media-enum';

// const PLACEHOLDERS = {
//   [SMALL]: 'https://picsum.photos/175/100?random={RND}',
//   [GEO]: 'https://picsum.photos/175/210?random={RND}',
//   [NORMAL]: 'https://picsum.photos/278/300?random={RND}',
//   [LARGE]: 'https://picsum.photos/555/600?random={RND}',
//   [WIDE]: 'https://picsum.photos/1260/700?random={RND}',
// };

const OG_IMAGES = [WIDE, LARGE, NORMAL, GEO];

const sort = (a, b) => a.order - b.order;

export const getSize = (type) => {
  switch (type) {
    case SMALL:
      return { width: 175, height: 100 };
    case GEO:
      return { width: 175, height: 210 };
    case NORMAL:
      return { width: 278, height: 300 };
    case LARGE:
      return { width: 555, height: 600 };
    case WIDE:
      return { width: 1260, height: 700 };
  }
  return { width: 175, height: 100 };
};

// export const placeholderImage = (a) => {
//   const size = typeof a === 'object' && typeof a.size === 'string' ? a.size : a;
//   return MediaType.create({
//     // eslint-disable-next-line radix
//     link: PLACEHOLDERS[size].replace('{RND}', parseInt(Math.random() * 1000)),
//     order: 0,
//     type: IMAGE,
//     size: size,
//     title: '',
//     alt: '',
//   });
// };

export const filterMedia = (media, { type, size }) => {
  const list = media
    .filter((item) => !type || (item.type === type && !size) || item.size === size)
    .map(el => ({ ...el }))
    .sort(sort);
  return list;
};

export const firstImage = (media, sizeObject) => {
  const size = typeof sizeObject === 'object' && typeof sizeObject.size === 'string' ? sizeObject.size : sizeObject;
  const mediaItem = media
    .map(el => ({ ...el }))
    .sort(sort)
    .find((item) => (item.type === 'image' && !size) || item.size === size);
  return mediaItem && {
    ...mediaItem
  };
};

export const openGraphImage = (media) => {
  for (let i = 0; i < OG_IMAGES.length; i++) {
    let image = firstImage(media, OG_IMAGES[i]);
    if (image) {
      return image.link;
    }
  }
  return "";
};

import { useRef, useEffect, useCallback } from 'react';
import axios from "axios";

const PROMISE = "promise";
const TOKEN = "token";
const TIMER = "timer";

function makeCancelable(promise) {
  let isCanceled = false;

  const wrappedPromise = new Promise((resolve, reject) => {
    promise
      .then(val => (isCanceled ? reject(new Error("Promise was canceled")) : resolve(val)))
      .catch(error => (isCanceled ? reject(new Error("Promise was canceled")) : reject(error)));
  });

  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    },
  };
}

export function useCancellable() {
  const cancellable = useRef([]);

  function clear(key, type) {
    const index = cancellable.current.findIndex(({ key: k, type: t }) => type === t && k === key);
    if (index != -1) {
      let [{item}] = cancellable.current.splice(index, 1);
      switch (type) {
        case PROMISE:
          item.cancel();
          break;
        case TOKEN:
          item.cancel();
          break;
        case TIMER:
          clearTimeout(item);
          break;
      }
    }
  }

  useEffect(() => {
    cancellable.current = cancellable.current || [];
    return () => {
      cancellable.current.forEach(({ type, item }) => {
        switch (type) {
          case PROMISE:
            item.cancel();
            break;
          case TOKEN:
            item.cancel();
            break;
          case TIMER:
            clearTimeout(item);
            break;
        }
      });
      cancellable.current = [];
    };
  }, []
  );

  const cancellablePromise = useCallback((p, key = "undefined") => {
    clear(key, PROMISE);
    const cPromise = makeCancelable(p);
    cancellable.current.push({ key, type: PROMISE, item: cPromise });
    return cPromise.promise;
  }, []);

  const cancellableToken = useCallback((key = "undefined") => {
    clear(key, TOKEN);
    const cancelRequest = axios.CancelToken.source();
    cancellable.current.push({ key, type: TOKEN, item: cancelRequest });
    return cancelRequest.token;
  }, []);

  const cancellableTimer = useCallback((fn, delay, key = "undefined") => {
    clear(key, TIMER);
    let uid = setTimeout(fn, delay);
    cancellable.current.push({ key, type: TIMER, item: uid });
    return uid;
  }, []);

  const cancelPromise = useCallback((key = "undefined") => {
    clear(key, PROMISE);
  }, []);

  const cancelToken = useCallback((key = "undefined") => {
    clear(key, TOKEN);
  }, []);

  const cancelTimer = useCallback((key = "undefined") => {
    clear(key, TIMER);
  }, []);

  return { cancellablePromise, cancellableToken, cancellableTimer, cancelPromise, cancelToken, cancelTimer };
}
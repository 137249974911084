function processStr(str, separator, pluralResolver) {
  const r = str.split(separator);
  const first = r[0];
  const forms = r.slice(1);
  const formLen = forms.length;
  if (!formLen) return first;

  let n = parseInt(first.match(/\d+/));
  if (typeof pluralResolver !== "function" || isNaN(n)) {
    str = first + (forms[0] || "");
  } else {
    n = pluralResolver(n);
    if (typeof n !== "number") {
      str = first + (forms[0] || "");
    } else {
      if (n < 0) {
        n = 0;
      } else if (n >= formLen) {
        n = formLen - 1;
      }
      str = first + (forms[n] || "");
    }
  }
  return str;
}

export default {
  name: 'num2word',
  type: 'postProcessor',

  options: {
    num2wordSeparator: ';',
    num2wordRegex: /\({2}[^)]+\){2}/g,
    num2wordBkts: 2,
    num2wordPlurals: {
      ru: (num) => {
        const rest_10 = num % 10;
        const rest_100 = num % 100;
        if ((rest_100 === 1 || rest_10 === 1)) return 0;
        if (rest_10 >= 2 && rest_10 <= 4) return 1;
        return 2;
      },
      en: (num) => {
        if (num > 1) return 1;
        return 0;
      },
      ar: (num) => {
        if (num > 1) return 1;
        return 0; 
      }
    }
  },

  setOptions(options) {
    this.options = {...this.options, ...options};
  },

  process(value, key, options, translator) {
    const matches = value.match(this.options.num2wordRegex);
    if (!matches) return value;

    const pluralResolver = this.options.num2wordPlurals && this.options.num2wordPlurals[translator && translator.language];
    const len = matches.length;
    for (let i = 0; i < len; i++) {
      let m = matches[i];
      let p = processStr(m.substr(this.options.num2wordBkts, m.length - this.options.num2wordBkts*2), this.options.num2wordSeparator, pluralResolver);
      value = value.replace(m, p);
    }

    return value;
  }
};
//Static links
export const MAIN_LINK = "/";
export const PROFILE_LINK = "/profile";
export const FAVORITE_LINK = "/favorite";
export const CHECKOUT_LINK = "/checkout";
export const ABOUT_COMPANY_LINK = "/about";
export const CONFIDENTIALITY_LINK = "/privacy-policy";
export const TERMS_LINK = "/conditions";
export const PAYMENT_LINK = "/payment";
export const NOT_FOUND_LINK = "/not-found";
export const _GIVE_FEEDBACK = "/review";
export const _PAYMENT_RESULT_SUCCESS = "/payment-success";
export const _PAYMENT_RESULT_FAIL = "/payment-fail";

//Dynamic links
export const COUNTRY_LINK = "/country";
export const CITY_LINK = "/city";
export const DIVISION_LINK = "/division";
export const CATEGORY_LINK = "/category";
export const SERVICE_LINK = "/service";
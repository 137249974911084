import { useTranslation } from "react-i18next";
import { useTimeFormater, useTimeFormaterCancel } from "./hook-time-formater";


export const useCreateOptions = ({ duration, durationFrom, confirm, cancel }) => {
  const { t } = useTranslation();

  const mainOptions = [
    !!duration && {
      title: t("label.the_duration"),
      value: useTimeFormater(duration)
    },
    !!durationFrom && {
      title: t("label.the_duration"),
      value: useTimeFormater(durationFrom, "from")
    },
    !!confirm && {
      title: t("label.the_confirmation"),
      value: confirm === 1 ? t("label.instant") : useTimeFormater(confirm, "up_to"),
    },
    {
      title: t("label.ticket"),
      value: t("label.mobileOrPrinted")
    },
    !!cancel && {
      title: t("label.theFreeCancel"),
      value: cancel == 1 ? t("text.cancelNotAvailable") : useTimeFormaterCancel(cancel, "notice")
    }
  ];
  return [...mainOptions.filter(opt => opt)];
};

export const meta200Letters = (text) => text.replace(/<.+?>/gm, '').replace(/\n|\r/g, ' ').slice(0, 200);


export const separateEmail = (str = '') => {
  if (str.length < 21 ) return str;

  const firstPart = str.replace(/@.*/gm, '');
  const secondPart = str.match(/@.*/gm, '');
  return `${firstPart} ${secondPart}`;
};


export const contentCutEnter = (content) => content.replace(/\n|\r/g, '');


import ruLocale from 'date-fns/locale/ru';
import enLocale from 'date-fns/locale/en-US';
import { useEffect, useState } from 'react';

function getLocale(lang) {
  switch(lang) {
    case "ru": return ruLocale;
    default: return enLocale;
  }
}

export function useDateLocale(i18n) {
  const [locale, setLocale] = useState(getLocale(i18n.language));

  useEffect(() => {
    setLocale(getLocale(i18n.language));
  }, [i18n, i18n.language]);

  return locale;
}
export const MENU_WND = "menu";
export const LOGIN_WND = "login";
export const SEARCH_WND = "search";
export const HELP_WND = "help";
export const RANDOM_WND = "random";
export const ADD_ORDER_WND = "add_order";
export const EDIT_ORDER_WND = "edit_order";
export const EDIT_ORDER_STEPS_WND = "edit_steps";
export const PURCHASE_WND = "purchase";
export const PAYMENT_WND = "payment";
export const REVIEW_WND = "review";

export const FILTER_WND = "filter";
export const FILTER_SECTIONS_WND = "filter_divisions";
export const FILTER_DATES_WND = "filter_dates";
export const FILTER_PLACES_WND = "filter_places";

export const CURRENCY_DLG = "currency";
export const LANG_DLG = "lang";
export const SHARE_DLG = "share";
export const COOKIE_DLG = "cookie";
export const SORTON_DLG = "sorton";
export const PLACE_DLG = "place";
export const GALLERY_DLG = "gallery";
export const ORDER_ACTIONS_DLG = "order_actions";

export const CHOISE_CONTENT_LANG = "choise_content_lang";

export const PROGRAM_DETAILS_DLG = 'program_details_dlg';
export const PROGRAM_DETAILS_WND = 'program_details_wnd';

import { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { parseLocation } from "../utils";
import { useStoreContext } from "../model/store";
import { SL, STATIC_LINKS } from "../screens";


function getWhere(pathname, { countryStore, cityStore, serviceStore, divisionStore, categoryStore }) {
  if (pathname && !STATIC_LINKS.includes(pathname)) {
    let country = countryStore.getCountry(pathname);
    if (country) return { country: country.id };

    let city = cityStore.getCity(pathname);
    if (city) return { city: city.id };

    let division = divisionStore.getDivision(pathname);
    if (division) return { division: division.id };

    let category = categoryStore.getCategory(pathname);
    if (category) return { category: category.id };

    let service = serviceStore.getService(pathname);
    if (service) return { service: service.id };
  }
  return null;
}

export function useWhereAre() {
  const store = useStoreContext();
  const location = useLocation();

  const whereAre = useMemo(() => {
    const { pathname } = parseLocation(location);
    const where = getWhere(pathname, store);
    if (where) {
      if (where.country) {
        return { location_key: `${SL.COUNTRY_LINK}@${where.country}`, where, pathname: SL.COUNTRY_LINK };
      } else if (where.city) {
        return { location_key: `${SL.COUNTRY_LINK}@${where.city}`, where, pathname: SL.CITY_LINK };
      } else if (where.division) {
        return { location_key: `${SL.DIVISION_LINK}@${where.division}`, where, pathname: SL.DIVISION_LINK };
      } else if (where.category) {
        return { location_key: `${SL.CATEGORY_LINK}@${where.category}`, where, pathname: SL.CATEGORY_LINK };
      } else if (where.service) {
        return { location_key: `${SL.SERVICE_LINK}@${where.service}`, where, pathname: SL.SERVICE_LINK };
      }
    } else if (pathname == SL.MAIN_LINK) {
      return { location_key: SL.MAIN_LINK, where: { main: true }, pathname: pathname };
    }
    return { location_key: pathname, pathname: pathname, where: {} };
  }, [location.pathname]);

  return whereAre;
}

export function usePlace() {
  const store = useStoreContext();
  const { countryStore, cityStore, serviceStore, divisionStore, categoryStore } = store;
  const location = useLocation();

  const [place, setPlace] = useState({ city: 0, country: 0 });

  useEffect(() => {
    const { pathname } = parseLocation(location);
    const where = getWhere(pathname, store);

    if (where) {
      if (where.country) {
        let country = countryStore.getCountry(where.country);
        setPlace({ city: 0, country: country.id });
      } else if (where.city) {
        setPlace({ country: 0, city: where.city });
      } else if (where.division) {
        let division = divisionStore.getDivision(where.division);
        setPlace({ country: 0, city: division?.city_id || 0 });
      } else if (where.category) {
        let category = categoryStore.getCategory(where.category);
        let division = divisionStore.getDivision(category.division_id);
        setPlace({ country: 0, city: division?.city_id || 0 });
      } else if (where.service) {
        let service = serviceStore.getService(where.service);
        if (service.city_id && service.city_id.length == 1) {
          setPlace({ country: 0, city: service.city_id[0] });
        } else if (service.country_id && service.country_id.length == 1) {
          setPlace({ city: 0, country: service.country_id[0] });
        }
      }
    }
  }, [location.pathname, serviceStore.services, categoryStore.depSize, divisionStore.depSize, cityStore.depSize, countryStore.countries]);

  return place;
}

class TagManager {

  constructor() {
    this._waiting = [];
    this._waitingUID = 0;
    this._currency = "";
  }

  setCurrency(currency) {
    this._currency = currency.toUpperCase();
  }

  addToCart({ program_id, program_name, price, quantity = 1 }) {
    this._send({
      'event': 'add_to_cart',
      'ecommerce': {
        'items': [{
          'item_name': program_name,
          'item_id': program_id,
          'price': price.toString(),
          'quantity': quantity.toString(),
        }]
      }
    });
  }

  removeFromCart({ program_id, program_name, price, quantity = 1 }) {
    this._send({
      'event': 'remove_from_cart',
      'ecommerce': {
        'items': [{
          'item_name': program_name,
          'item_id': program_id,
          'price': price.toString(),
          'quantity': quantity.toString(),
        }]
      }
    });
  }

  beginCheckout(items) {
    this._send({
      'event': 'begin_checkout',
      'ecommerce': {
        'items': items.map(({ program_id, program_name, price, quantity = 1 }) => ({
          'item_name': program_name,
          'item_id': program_id,
          'price': price.toString(),
          'quantity': quantity.toString(),
        }))
      }
    });
  }

  purchase(items, totalPrice) {
    this._send({
      'event': 'purchase',
      'ecommerce': {
        'purchase': {
          'transaction_id': `T${parseInt(Math.random() * 100000)}`,
          'affiliation': process.env.TITLE,
          'value': totalPrice.toString(),
          'currency': this._currency,
          'items': items.map(({ program_id, program_name, price, quantity = 1 }) => ({
            'item_name': program_name,
            'item_id': program_id,
            'item_price': price.toString(),
            'quantity': quantity.toString(),
          }))
        }
      }
    });
  }

  selectService({ service_id, service_name, service_price }) {
    this._send({
      'event': 'select_item',
      'ecommerce': {
        'items': [{
          'item_name': service_name,
          'item_id': service_id,
          'price': service_price,
          'item_variant': "service",
          'quantity': '1',
        }]
      }
    });
  }

  selectProgram({ program_id, program_name, program_price }) {
    this._send({
      'event': 'select_item',
      'ecommerce': {
        'items': [{
          'item_name': program_name,
          'item_id': program_id,
          'price': program_price,
          'item_variant': "program",
          'quantity': '1',
        }]
      }
    });
  }

  _send(obj) {
    const data = { ...obj, currency: this._currency };
    if (process.env.DEBUG_MODE === "true") {
      console.log("TagManager:", data);
    }
    if (!process.env.GOOGLE_TAG_ID) return;
    clearTimeout(this._waitingUID);
    if (typeof window.dataLayer === "object" && typeof window.dataLayer.push === "function") {
      window.dataLayer.push(data);
    } else {
      this._waiting.push(data);
      this._waitingUID = setTimeout(() => {
        let waiting = this._waiting.splice();
        this._waiting = [];
        waiting.forEach(data => {
          this._send(data);
        });
      }, 500);
    }
  }
}

const tagManager = new TagManager();
export { tagManager };
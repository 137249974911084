import Service from './service';

class PreloadService extends Service {

  constructor(name, store) {
    super(name);
    this.store = store;
  }

  run() {
    return new Promise((resolve) => {
      Promise.all([this.store.countryStore.fetchCountries()])
        .then(() => resolve({ [this.name]: true }));
    });
  }
}

export default PreloadService;
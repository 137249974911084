// @ts-nocheck
import { interpolate } from "../../src/utils";
import { num2word } from "../plugins";
export const PROGRAMS_BLOCK = "both_programs";
export const ROAD_MAP = "road_map";
export const CONTENT = "content";

const REG_DIV = /<\/?div[^>]*>/g;
const REG_CONTENT = /<div([^>]*)>(<h2[^>]*>.*?<\/h2>)?(.*?)?<\/div>/;
const REG_BLOCK = /<div\b[^<]*(?:(?!<\/div>)<[^<]*)*<\/div>/;
const REG_CLASS = /class=["']([\w\d\s-]+)["']/;
const REG_BLOCK_MAP = {
  [PROGRAMS_BLOCK]: /^(?:both|complex|simple|)[-_]?programs$/,
  [ROAD_MAP]: /^(?:service|program|)[-_]?map$/,
};

const REG_TOTAL = /\{{2}total\}{2}/g;

export const splitContent = (content) => {
  if (typeof content !== "string") {
    content = "";
  }

  content = content.replace(/\n|\r/g, "");

  let match = null;
  let result = [];
  do {
    match = content.match(REG_BLOCK);
    if (match && match.length) {
      const index = match.index;
      const [text] = match;
      const [txt, divContent = "", header = "", subheader = ""] =
        text.match(REG_CONTENT);
      if (index > 0) {
        result.push({
          header: "",
          subheader: "",
          text: content.slice(0, index),
          type: CONTENT,
        });
      }

      const classMatch = divContent.match(REG_CLASS);
      let classType =
        ((classMatch && classMatch[1]) || "")
          .split(/\s+/)
          .map((className) => {
            for (let name in REG_BLOCK_MAP) {
              if (REG_BLOCK_MAP[name].test(className)) {
                return name;
              }
            }
            return "";
          })
          .filter((v) => !!v)[0] || CONTENT;

      if (classType === CONTENT || classType === ROAD_MAP) {
        result.push({
          header,
          subheader: "",
          text: subheader,
          type: classType,
        });
      } else {
        result.push({ header, subheader, text: "", type: classType });
      }

      content = content.replace(text, "");
    }
  } while (match && match.length);

  result.push({
    header: "",
    subheader: "",
    text: content.replace(REG_DIV, ""),
    type: CONTENT,
  });
  return result.filter(
    ({ header, subheader, text }) => !!header || !!subheader || !!text
  );
};

/**
 * Deleted all [field]: null
 */
export const dataAdapter = (data) => {
  if (Array.isArray(data)) {
    return data.map((d) => {
      return d !== null && typeof d === "object" ? dataAdapter(d) : d;
    });
  } else if (data !== null && typeof data === "object") {
    const dataClone = {};
    for (const name in data) {
      const value = data[name];
      if (value !== null && typeof value === "object") {
        dataClone[name] = dataAdapter(value);
      } else if (value !== null) {
        dataClone[name] = value;
      }
    }
    return dataClone;
  }
  return data;
};

export const replaceTotal = (text, props, lang) => {
  return num2word.process.call(
    num2word,
    interpolate(text, props),
    "",
    {},
    {
      language: lang,
    }
  );
}
  

import { addMinutes, differenceInCalendarDays } from "date-fns";
import { format, set } from "date-fns/esm";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { parseDate, availableDates, availableTimes, parseTime, is24Hours, minutesToTime, dateToMinutes } from "../../core/helpers";
import { useDateLocale } from "./hook-date-locale";

const getNow = () => new Date();

const TIME_RANGE = /(\d{1,2}:\d{2})\s*-\s*(\d{1,2}:\d{2})/;

const joinTimes = (times, isPreview) => {
  if (isPreview) {
    let len = TIME_RANGE.test(times[0]) ? 2 : 3;
    return (len < times.length) ? times.slice(0, len).join(", ") + " ..." : times.join(", ");
  }
  return times.join(", ");
};

export function useNearestDataTime(program) {
  const { t, i18n } = useTranslation();
  const locale = useDateLocale(i18n);
  const { available_dates, unavailable_dates, days_of_week, available_times, isPreview } = program;

  const data = useMemo(() => {
    let dates = availableDates({ available_dates, unavailable_dates, days_of_week });
    if (!dates.length) return;
    let nearestDate = dates[0];
    let nearestTime;

    let times = availableTimes({ available_times, withTimeRange: false });
    const now = getNow();
    const haveTime = (!Array.isArray(times) && !!times.from && !!times.to) || (Array.isArray(times) && times.length > 0);
    if (!haveTime) {
      times = { from: "00:00", to: "23:59" };
    }

    const startDayMinutes = dateToMinutes(set(now, { hours: 0, minutes: 0, seconds: 0 }));
    const nowMinutes = dateToMinutes(now) - startDayMinutes;
    const timeBeforeMinutes = parseTime(is24Hours(times) ? process.env.BOOKING_MIN_TIME_24_BEFORE_START : process.env.BOOKING_MIN_TIME_BEFORE_START).minutes;
    const workTimeFromMinutes = parseTime(process.env.BOOKING_WORK_TIME_FROM).minutes;
    const workTimeToMinutes = parseTime(process.env.BOOKING_WORK_TIME_TO).minutes;
    let curMinutes = nowMinutes;
    if (nowMinutes < workTimeFromMinutes) {
      curMinutes = workTimeFromMinutes;
    } else if (nowMinutes > workTimeToMinutes) {
      curMinutes = workTimeFromMinutes + 24 * 60;
    }
    curMinutes += startDayMinutes;

    times = Array.isArray(times) ? times : [times];

    for (let d = 0; d < dates.length; d++) {
      const date = dates[d];
      for (let t = 0; t < times.length; t++) {
        const time = times[t];
        const dateFrom = typeof time === "string" ? time.split("-")[0] : time.from;

        let dateMinutes = dateToMinutes(addMinutes(date, parseTime(dateFrom).minutes));
        if (dateMinutes - curMinutes >= timeBeforeMinutes || (typeof time === "object" && dateMinutes - curMinutes + parseTime(time.to).minutes - parseTime(time.from).minutes >= timeBeforeMinutes)) {
          nearestDate = date;
          if (typeof time === "object" && !is24Hours(time) && dateMinutes - curMinutes <= timeBeforeMinutes) {
            let minTime = curMinutes - dateToMinutes(date) + timeBeforeMinutes;
            nearestTime = { from: minutesToTime(minTime - (minTime % 10)), to: time.to };
          }
          else {
            nearestTime = typeof time === "object" ? time : joinTimes(times, isPreview);
          }
          d = Number.MAX_SAFE_INTEGER;
          break;
        }
      }
    }

    let dateFormat = "";

    switch (differenceInCalendarDays(nearestDate, now)) {
      case 0:
        dateFormat = t('label.today');
        break;
      case 1:
        dateFormat = t('label.tomorrow');
        break;
      case 2:
        dateFormat = t('label.dayAfterTomorrow');
        break;
      default:
        dateFormat = format(parseDate(nearestDate), "dd MMM yyyy", { weekStartsOn: 1, locale });
        break;
    }
    if (isPreview) {
      if (!haveTime) return t('text.availableDate', { date: dateFormat });
      if (typeof nearestTime === "string") return t('text.availableDateTime', { date: dateFormat, time: nearestTime });
      if (nearestTime.from === "00:00" && nearestTime.to === "23:59") return t('text.availableDateTime_at_any_time', { date: dateFormat });
      return t('text.availableDateTime_from_to', { date: dateFormat, ...nearestTime });
    } else {
      if (!haveTime) return t('text.nearestDate', { date: dateFormat });
      if (typeof nearestTime === "string") return t('text.nearestDateTime', { date: dateFormat, time: nearestTime });
      if (nearestTime.from === "00:00" && nearestTime.to === "23:59") return t('text.nearestDateTime_at_any_time', { date: dateFormat });
      return t('text.nearestDateTime_from_to', { date: dateFormat, ...nearestTime });
    }
  }, []);

  return data;
}
import store from "store2";

const USER_STATE = "user_state";
const BASKET_STATE = "basket_state";
const I18N_STATE = "i18n_state";

store.session.clear();
store.page.clear();

export const LocalStorageService = {

  setUserState: (st) => {
    store.set(USER_STATE, JSON.stringify(st || {}), true);
  },
  getUserState: () => {
    return JSON.parse(store.get(USER_STATE) || "{}");
  },
  hasUserState: () => {
    return store.has(USER_STATE);
  },

  setBasketState: (st) => {
    store.set(BASKET_STATE, JSON.stringify(st || {}), true);
  },
  getBasketState: () => {
    return JSON.parse(store.get(BASKET_STATE) || "{}");
  },
  hasBasketState: () => {
    return store.has(BASKET_STATE);
  },

  getI18nState: () => {
    return JSON.parse(store.get(I18N_STATE) || "{}");
  },

  setI18nState: (st) => {
    store.set(I18N_STATE, JSON.stringify(st || {}), true);
  },

  _clear: () => {
    store.clear();
  }
};

export default LocalStorageService;
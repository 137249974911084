import qs from "query-string";
import { decode, encode } from "js-base64";

const WND_ = "wnd_";

const toArray = (list) => {
  if (Array.isArray(list)) return list.concat();
  if (typeof list === "string" && list.length > 0) return list.split(",");
  if (typeof list === "number") return [list];
  return [];
};

export default class QueryParams {

  constructor(search) {
    this._sorton = [];
    this._date = [];
    this._price = [];
    this._category_types = [];
    this._windows = {};

    this.parse(search);
  }

  parse(search) {
    let object = {};
    if (typeof search === "object" && !!search && typeof search.search === "string") {
      search = search.search;
    }

    if (typeof search === "string") {
      object = qs.parse(search || "", { arrayFormat: "comma", sort: false });
    }

    this._sorton = toArray(object["sorton"]);
    this._date = toArray(object["date"]);
    this._price = toArray(object["price"]);
    this._category_types = toArray(object["category_types"]);
    this._windows = [];

    const windowsName = [...new Set(Object.keys(object).filter(name => name.startsWith(WND_)))];

    windowsName.forEach((name) => {
      const payload = object[name];
      if (name.startsWith(WND_)) {
        this._windows.push({ name, payload: payload == 1 || !payload ? 1 : JSON.parse(decode(payload)) });
      }
    });
  }

  get sorton() {
    return this._sorton && this._sorton.length > 0 && this._sorton[0] || process.env.SORTON;
  }

  deleteWnd(name) {
    let wndName = WND_ + name;
    this._windows = this._windows.filter(({ name }) => wndName !== name);
  }

  addWindow(name, payload) {
    let wndName = WND_ + name;
    let wndIndex = this._windows.findIndex(({ name }) => name === wndName);
    const instance = { name: wndName, payload: typeof payload === "object" && Object.keys(payload).length > 0 ? { ...payload } : 1 };
    if (wndIndex != -1) {
      this._windows[wndIndex] = instance;
    } else {
      this._windows.push(instance);
    }
  }

  getWindow(name) {
    let wndName = WND_ + name;
    let wndInstance = this._windows.find(({ name }) => name === wndName);
    let props = wndInstance?.payload;
    return typeof props === "object" ? { ...props } : props;
  }

  windowsName() {
    return this._windows.map(({ name }) => name.replace(WND_, ""));
  }

  hasWindow(name) {
    let wndName = WND_ + name;
    return !!this._windows.find(({ name }) => name === wndName);
  }

  deleteSorton() {
    this._sorton = [];
  }

  addSorton(value) {
    this._sorton = toArray(value);
  }

  deletePrice() {
    this._price = [];
  }

  addPrice(value) {
    this._price = toArray(value);
  }

  deleteDate() {
    this._date = [];
  }

  addDate(value) {
    this._date = toArray(value);
  }

  deleteCategoryTypes() {
    this._category_types = [];
  }

  addCategoryTypes(value) {
    this._category_types = toArray(value);
  }

  toString() {
    return qs.stringify({
      category_types: this._category_types,
      date: this._date,
      price: this._price,
      sorton: this._sorton,
      ...this._windows.reduce((prev, { name, payload }) => Object.assign(prev, { [name]: typeof payload == "object" && Object.keys(payload).length > 0 ? encode(JSON.stringify(payload)) : null }), {})
    }, { arrayFormat: "comma", sort: false });
  }
}

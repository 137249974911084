export const LOGIN = 'login';
export const LOGOUT = 'logout';
export const REGISTER = 'register';
export const REFRESH = 'refresh';
export const CATEGORY = 'category';
export const CATEGORIES = 'categories';
export const CITY = 'city';
export const CITIES = 'cities';
export const COUNTRY = 'country';
export const DIVISION = 'division';
export const DIVISIONS = 'divisions';
export const LOCATION = 'location';
export const PROGRAM = 'program';
export const PROGRAMS = 'programs';
export const SERVICE = 'service';
export const SERVICES = 'services';
export const LIST = 'list';
export const ALL = 'all';
export const BY = 'by';
export const IDS = 'ids';
export const TYPE = 'type';
export const TYPES = 'types';
export const URL = 'url';
export const CHECK = 'check';
export const SUBTYPE_DIVISION = 'division';
export const SUBTYPE_CATEGORY = 'category';
export const AUTH_CODE = 'auth-code';
export const SEARCH = 'search';
export const SETTING = 'setting';
export const LANG = 'lang';
export const CY = 'cy';
export const SORTON = 'sorton';
export const PRICE = 'price';
export const ADDITIONALS = 'additionals';
export const SAVE = 'save';
export const REVIEWS = 'reviews';
export const REVIEW = 'review';
export const INFO = 'info';
export const CREATE = 'create';

export const BASKET = 'basket';
export const PROCESS = 'process';
export const PREPAID = "prepaid";
export const PAYMENT = "payment";
export const PAID = "paid";

export const API_OBJECT = {
  LOGIN,
  LOGOUT,
  REGISTER,
  REFRESH,
  CATEGORY,
  CATEGORIES,
  CITY,
  CITIES,
  COUNTRY,
  DIVISION,
  DIVISIONS,
  LOCATION,
  PROGRAM,
  PROGRAMS,
  SERVICE,
  SERVICES,
  LIST,
  TYPE,
  TYPES,
  ALL,
  SUBTYPE_DIVISION,
  SUBTYPE_CATEGORY,
  URL,
  CHECK,
  AUTH_CODE,
  SEARCH,
  IDS,
  SETTING,
  LANG,
  CY,
  SORTON,
  PRICE,
  ADDITIONALS,
  SAVE,
  BASKET, 
  PROCESS,
  REVIEWS,
  PREPAID,
  REVIEW,
  CREATE,
  INFO,
  PAYMENT,
  PAID
};

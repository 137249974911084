import "./window.pure.scss";
import { useCallback, useEffect, lazy, Suspense, useState } from "react";
import * as WN from "./window-name";
import WindowEmitter, { WND_OPEN, WND_CLOSE, WND_CLOSED, WND_OPENED } from "./window-emitter";
import { appRoot, noScroll } from "../utils";
import QueryParams from "../utils/query-params";
import { withRouter } from "react-router-dom";
import { CircleIndicator } from "../components/common/indicators";
import { useCancellable } from "../hooks";
import { useStoreContext } from "../model/store";

const IGNORE_ADD = [
  WN.CHOISE_CONTENT_LANG,
  WN.PAYMENT_WND,
  WN.PURCHASE_WND,
  WN.COOKIE_DLG,
  WN.REVIEW_WND
];

const CLEAR_AUTO_LAUNCHE = [
  WN.MENU_WND, WN.LANG_DLG, WN.CURRENCY_DLG, WN.SHARE_DLG, WN.PROGRAM_DETAILS_DLG, WN.SORTON_DLG, WN.PLACE_DLG, WN.GALLERY_DLG, WN.ORDER_ACTIONS_DLG
];

const WindowLibrary = lazy(() => import(/* webpackChunkName: "window-library" */"./window-library"));

const appContainer = appRoot();
const CLOSE_TIMEOUT = 300;
const WND_ = "wnd_";

function WindowProvider({ appReady, location }) {

  const { historyStore } = useStoreContext();
  const [follow, setFollow] = useState(false);
  const [state, setState] = useState({ wnds: [], open: [] });

  const { cancellableTimer } = useCancellable();

  const onTryToOpen = useCallback(({ wnd, payload }) => {
    setState((state) => {
      const isInit = !!state.wnds.find((f) => f.wnd === wnd);
      const wndData = { wnd, isOpen: 1, order: state.open.length + 1, openData: payload, closeData: {} };

      const newWnds = isInit ? state.wnds.map((f) => wnd === f.wnd && f.isOpen === 0 ? { ...f, ...wndData } : f) : state.wnds.concat(wndData);
      const newState = newWnds ? { ...state, wnds: newWnds, open: newWnds.filter(({ isOpen }) => isOpen === 1, 0).map(({ wnd }) => wnd) } : state;
      return state.open.length == newState.open.length ? state : newState;
    });
  }, []);

  const onTryToClose = useCallback(({ wnd, payload }) => {
    setState((state) => {
      const isInit = !!state.wnds.find((f) => f.wnd === wnd);
      const newWnds = isInit && state.wnds.map((f) => wnd === f.wnd && f.isOpen === 1 ? { ...f, isOpen: 0, closeData: payload } : f);
      const newState = newWnds ? { ...state, wnds: newWnds, open: newWnds.filter(({ isOpen }) => isOpen === 1, 0).map(({ wnd }) => wnd) } : state;
      return state.open.length == newState.open.length ? state : newState;
    });
  }, []);

  const onClosed = useCallback(({ wnd, payload }) => {
    const { search, hash } = historyStore.getCurrent();
    let params = new QueryParams(search);
    if (params.hasWindow(wnd)) {
      params.deleteWnd(wnd);
      historyStore.push({ search: params.toString(), hash });
      WindowEmitter.removed(wnd, payload);
    }
  }, []);

  const onOpened = useCallback(({ wnd, payload }) => {
    const { search, hash } = historyStore.getCurrent();
    let params = new QueryParams(search);
    if (!IGNORE_ADD.includes(wnd) && !params.hasWindow(wnd)) {
      params.addWindow(wnd, payload);
      historyStore.push({ search: params.toString(), hash });
      WindowEmitter.added(wnd, payload);
    }
  }, []);

  useEffect(() => {
    WindowEmitter.on(WND_OPEN, onTryToOpen);
    WindowEmitter.on(WND_CLOSE, onTryToClose);
    WindowEmitter.on(WND_CLOSED, onClosed);
    WindowEmitter.on(WND_OPENED, onOpened);
    return () => {
      WindowEmitter.off(WND_OPEN, onTryToOpen);
      WindowEmitter.off(WND_CLOSE, onTryToClose);
      WindowEmitter.off(WND_CLOSED, onClosed);
      WindowEmitter.off(WND_OPENED, onOpened);
    };
  }, []);

  useEffect(() => {
    if (state.open.length === 1) {
      noScroll.on(appContainer);
    } else if (state.open.length === 0) {
      noScroll.off(appContainer);
    }
  }, [state.open.length]);

  // Clear windows
  useEffect(() => {
    if (!appReady) return;
    const { search, hash } = historyStore.getCurrent();
    let params = new QueryParams(search);
    let before = params.windowsName();
    let wndList = Object.values(WN);
    let needOpen = [];
    before.forEach(wnd => {
      if (wndList.includes(wnd) && !CLEAR_AUTO_LAUNCHE.includes(wnd)) {
        needOpen.push({ wnd, payload: params.getWindow(wnd) });
      } else {
        params.deleteWnd(wnd);
      }
    });
    if (before.length != params.windowsName().length) {
      historyStore.replace({ search: params.toString(), hash });
    }
    needOpen.forEach(data => {
      onTryToOpen(data);
    });

    setFollow(true);
  }, [appReady]);

  useEffect(() => {
    if (!follow) return;

    cancellableTimer(() => {
      let params = new QueryParams(historyStore.getCurrent());
      Object.values(WN).forEach(wnd => {
        if (params.hasWindow(wnd) && !state.open.includes(wnd)) {
          onTryToOpen({ wnd, payload: params.getWindow(wnd) });
        } else if (!params.hasWindow(wnd) && state.open.includes(wnd)) {
          onTryToClose({ wnd });
        }
      });
    }, CLOSE_TIMEOUT);

  }, [follow, historyStore.index, location]);

  return state.wnds.length > 0 && <Suspense fallback={<div className="modalContent side__top transparentBackground overlayOrder-1" style={{ backgroundColor: 'rgba(0,0,0,.5)' }}>
    <CircleIndicator active margin="mt-5" />
  </div>}>
    <WindowLibrary wnds={state.wnds} closeTimeout={CLOSE_TIMEOUT} />
  </Suspense>;
}
export default withRouter(WindowProvider);
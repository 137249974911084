import { WindowEmitter, WN } from '../windows';
import { WND_CLOSED } from '../windows/window-emitter';
import Service from './service';
import { userBot } from "../utils";

class CookieService extends Service {

  constructor(store) {
    super();
    this.store = store;
    this.onWndClosed = ({ wnd }) => {
      if (wnd === WN.COOKIE_DLG) {
        this.store.userStore.iCookieAgree();
        WindowEmitter.off(WND_CLOSED, this.onWndClosed);
      }
    };
  }

  run() {
    return new Promise((res) => {
      setTimeout(() => {
        if (!userBot() && !this.store.userStore.cookieAgree) {
          WindowEmitter.open(WN.COOKIE_DLG);
          WindowEmitter.on(WND_CLOSED, this.onWndClosed);
        }
        res();
      }, 2000);
    });
  }
}

export default CookieService;
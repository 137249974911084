import EventEmitter from 'eventemitter3';

const eventEmitter = new EventEmitter();

export const WND_OPEN = "wnd_open";
export const WND_CLOSE = "wnd_close";

export const WND_CLOSED = "wnd_closed";
export const WND_OPENED = "wnd_opened";

export const WND_ADDED = "wnd_added";
export const WND_REMOVED = "wnd_removed";

const WindowEmitter = {
  on: (event, fn) => eventEmitter.on(event, fn),
  off: (event, fn) => eventEmitter.off(event, fn),
  once: (event, fn) => eventEmitter.once(event, fn),

  open: (wnd, payload = {}) => eventEmitter.emit(WND_OPEN, { wnd, payload }),
  close: (wnd, payload = {}) => eventEmitter.emit(WND_CLOSE, { wnd, payload }),
  closed: (wnd, payload = {}) => eventEmitter.emit(WND_CLOSED, { wnd, payload }),
  opened: (wnd, payload = {}) => eventEmitter.emit(WND_OPENED, { wnd, payload }),

  added: (wnd, payload = {}) => eventEmitter.emit(WND_ADDED, { wnd, payload }),
  removed: (wnd, payload = {}) => eventEmitter.emit(WND_REMOVED, { wnd, payload }),

  clear: (event) => eventEmitter.removeAllListeners(event),
};

Object.freeze(WindowEmitter);
window.WindowEmitter = WindowEmitter;
export default WindowEmitter;
import { addDays, addMonths } from "date-fns";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { availableDates, filterMedia, parseDate, roundPriceCent } from "../../core/helpers";
import { GEO, IMAGE, NORMAL } from "../../core/model/enum/media-enum";
import { useStoreContext } from "../model/store";
import { buildHref, cannonical, userBot, qsStringify } from "../utils";
import { encode } from "js-base64";
import { WN } from "../windows";
import { useCancellable } from ".";

const dateToStr = (date) => date.toISOString().split("T")[0];
const getPrice = (item) => roundPriceCent(item.discount_price || item.price);
const getContent = (item) => (item.content || "")
  .replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, "")
  .replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, "")
  .replace(/<\/tr>/gi, " ")
  .replace(/<[^>]*>/gi, "")
  .replace(/(?: +|&nbsp;)/gi, " ")
  .replace(/ ?[\r\n\t] ?/g, " ")
  .replace(/[\r\n\t]+/g, " ").trim().slice(0, 512);

const buildBrand = () => ({
  "brand": {
    "@type": "Brand",
    "name": "Take Travel",
    "logo": cannonical({ pathname: "/favicon/favicon-32x32.png" }),
  },
});

export const useServiceStructuredData = ({ service }) => {
  const { programStore, countryStore, cityStore, reviewStore } = useStoreContext();
  const { t } = useTranslation();

  const { cancellablePromise } = useCancellable();

  useEffect(() => {
    if (userBot()) {
      reviewStore.initReviewsData({ service_id: service.id, sorton: "reviewed_desc" });
      cancellablePromise(reviewStore.fetchReviews({ limit: +process.env.REVIEWS_SHOW_DEFAULT, offset: 0 }), "fetch-review")
        .catch(() => { });
    }
  }, []);

  const ldJSON = useMemo(() => {
    const serviceDescription = getContent(service) || t("seo.service.og_description", { serviceName: service.name || "" });
    const programs = programStore.getPrograms({ service: service.id });
    const amountPrograms = programs.length;
    const servicePrice = getPrice(service);
    const serviceImages = filterMedia(service.media || [], { type: IMAGE, size: NORMAL }).map(item => item.link);
    const country = service.country_id.length > 0 && countryStore.getCountry(service.country_id[0]);
    const city = service.city_id.length > 0 && cityStore.getCitiesList(service.city_id).find(city => city.country_id === service.country_id[0]);

    const product = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": service.name,
      "sku": `service${service.id}`,
      "description": serviceDescription,
      "image": serviceImages,
      ...buildBrand,
    };

    // AggregateRating
    if (service.rating > 0 && service.reviews > 0) {
      Object.assign(product, {
        "aggregateRating": {
          "@type": "AggregateRating",
          "ratingValue": parseInt(service.rating * 100) + "%",
          "ratingCount": String(service.reviews)
        }
      });

      const review = reviewStore.count > 0 && reviewStore.reviews.sort((a, b) => {
        if (a.like != b.like) return b.like - a.like;
        const aDate = parseDate(a.created_at);
        const bDate = parseDate(b.created_at);
        return aDate && bDate ? bDate.getTime() - aDate.getTime() : !!bDate - !!aDate;
      })[0];

      if (review) {
        Object.assign(product, {
          "review": {
            "@type": "Review",
            "reviewBody": review.text,
            "datePublished": dateToStr(parseDate(review.created_at) || addDays(Date.now() - 1)),
            "reviewRating": {
              "@type": "Rating",
              "bestRating": "1",
              "ratingValue": String(review.like),
              "worstRating": "0"
            },
            "author": {
              "@type": "Person",
              "name": review.username
            },
          }
        });
      }
    }

    // AggregateOffer
    if (amountPrograms > 1) {
      let list = programs.map((program) => getPrice(program)).sort((a, b) => a - b);

      Object.assign(product, {
        offers: {
          "@type": "AggregateOffer",
          "offerCount": String(amountPrograms),
          "priceCurrency": (service.currency || "").toUpperCase(),
          "lowPrice": String(list[0]),
          "highPrice": String(list[list.length - 1]),
        }
      });
    } else if (amountPrograms === 1) {
      Object.assign(product, {
        offers: {
          "@type": "Offer",
          "priceCurrency": (service.currency || "").toUpperCase(),
          "price": String(servicePrice),
          "itemCondition": "https://schema.org/NewCondition",
          "availability": "https://schema.org/InStock",
          "priceValidUntil": dateToStr(addMonths(Date.now(), 1)),
          "url": cannonical({ pathname: buildHref(service.url) }),
        }
      });
    }

    let events = [];
    if (amountPrograms > 0) {

      events = programs.map((program) => {
        const programImages = filterMedia(program.media || [], { type: IMAGE, size: NORMAL }).map(item => item.link);
        const dates = availableDates(program);
        const startDate = dates[0] || new Date();
        const event = {
          "@context": "https://schema.org",
          "@type": "Event",
          "name": program.name,
          "description": getContent(program) || serviceDescription,
          "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "startDate": dateToStr(startDate),
          "endDate": dateToStr(addDays(startDate, 1)),
          "image": programImages.length > 0 ? programImages : serviceImages,
          "organizer": {
            "@type": "Organization",
            "name": "Take Travel",
            "url": "https://take.travel"
          },
          "offers": {
            "@type": "Offer",
            "url": cannonical({ pathname: buildHref(service.url) }) + "?" + qsStringify({ [`wnd_${WN.ADD_ORDER_WND}`]: encode(JSON.stringify({ programId: program.id })) }),
            "priceCurrency": (service.currency || "").toUpperCase(),
            "price": String(getPrice(program)),
            "availability": "https://schema.org/InStock",
            "validFrom": dateToStr(addMonths(Date.now(), -1)),
          },
        };

        if (country && city) {
          Object.assign(event, {
            "location": {
              "@type": "Place",
              "name": country.name,
              "address": {
                "@type": "PostalAddress",
                "addressLocality": city.name,
                "streetAddress": city.name,
                "addressCountry": {
                  "@type": "Country",
                  "name": country.name,
                },
              }
            },
          });
        }
        return event;
      });
    }

    return JSON.stringify([product, ...events]);
  }, [programStore.depSize, countryStore.countries, cityStore.cities, reviewStore.count]);

  return ldJSON;
};

export const usePlaceStructuredData = ({ root, rootName, filterServices }) => {

  const { services, total } = filterServices;

  const { countryStore, cityStore } = useStoreContext();

  const { t } = useTranslation();

  const ldJSON = useMemo(() => {

    const product = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": root.name,
      "sku": `${rootName}${root.id}`,
      "description": root.og_description || t(`seo.${rootName}.og_description`, { [`${rootName}Name`]: root.name || "" }),
      "image": filterMedia(root.media || [], { type: IMAGE, size: GEO }).map(item => item.link),
      ...buildBrand,
    };


    const filtered = services.filter(({ random }) => !random);
    let events = [];
    if (filtered.length) {

      const priceSort = filtered.sort((a, b) => getPrice(a.data) - getPrice(b.data));
      const min = priceSort[0].data;
      const max = priceSort[priceSort.length - 1].data;
      const aggregateRating = filtered.reduce((prev, { data }) => ({ reviews: prev.reviews + data.reviews, rating: prev.rating + data.rating / filtered.length }), { rating: 0, reviews: 0 });
      Object.assign(product, {
        "offers": {
          "@type": "AggregateOffer",
          "offerCount": String(filtered.length),
          "priceCurrency": (min.currency || "").toUpperCase(),
          "lowPrice": String(getPrice(min)),
          "highPrice": String(getPrice(max)),
        },
      });

      if (aggregateRating.rating > 0 && aggregateRating.reviews > 0) {
        Object.assign(product, {
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": parseInt(aggregateRating.rating * 100) + "%",
            "ratingCount": String(aggregateRating.reviews)
          }
        });
      }

      events = filtered.map(({ data: service }) => {

        const dates = availableDates(service);
        const startDate = dates[0] || new Date();
        const country = service.country_id.length > 0 && countryStore.getCountry(service.country_id[0]);
        const city = service.city_id.length > 0 && cityStore.getCitiesList(service.city_id).find(city => city.country_id === service.country_id[0]);

        const event = {
          "@context": "https://schema.org",
          "@type": "Event",
          "name": service.name,
          "description": service.og_description || t("seo.service.og_description", { serviceName: service.name || "" }),
          "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "startDate": dateToStr(startDate),
          "endDate": dateToStr(addDays(startDate, 1)),
          "image": filterMedia(service.media || [], { type: IMAGE, size: NORMAL }).map(item => item.link),
          "organizer": {
            "@type": "Organization",
            "name": "Take Travel",
            "url": "https://take.travel"
          },
          "offers": {
            "@type": "Offer",
            "url": cannonical({ pathname: buildHref(service.url) }),
            "priceCurrency": (service.currency || "").toUpperCase(),
            "price": String(getPrice(service)),
            "availability": "https://schema.org/InStock",
            "validFrom": dateToStr(addMonths(Date.now(), -1)),
          },
        };

        if (country && city) {
          Object.assign(event, {
            "location": {
              "@type": "Place",
              "name": country.name,
              "address": {
                "@type": "PostalAddress",
                "addressLocality": city.name,
                "streetAddress": city.name,
                "addressCountry": {
                  "@type": "Country",
                  "name": country.name,
                },
              }
            },
          });
        }

        return event;
      });
    }

    return JSON.stringify([product, ...events]);
  }, [services, total, countryStore.countries, cityStore.cities]);

  return ldJSON;
};